<template>
    <a-modal
        :title="edit ? 'Редактировать календарь' : 'Добавить календарь'"
        :zIndex="5000"
        :visible="visible"
        :footer="null"
        :afterClose="afterClose"
        :width="600"
        @cancel="visible = false">
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules">
            <div class="lg:flex lg:items-start mb-4">
                <a-form-model-item ref="name" label="Название" prop="name" class="w-full mb-0">
                    <a-input v-model="form.name" size="large" />
                </a-form-model-item>
                <a-form-model-item v-if="!isMobile" ref="color" label="Цвет" prop="color" class="ml-2 mb-0">
                    <ColorPicker v-model="form.color" />
                </a-form-model-item>
                <a-form-model-item v-else ref="color" label="Цвет" prop="color" class="mt-3 mb-0">
                    <ColorPicker v-model="form.color" typeList />
                </a-form-model-item>
            </div>
            <a-form-model-item>
                <a-button 
                    type="primary" 
                    size="large" 
                    :loading="loading" 
                    block
                    @click="onSubmit()">
                    Сохранить календарь
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import ColorPicker from './ColorPicker.vue'
export default {
    props: {
        elementUpdate: {
            type: Function,
            default: () => {}
        },
        listReload: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        ColorPicker
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            edit: false,
            rules: {
                name: [
                    { required: true, message: 'Обязательно для заполнения', trigger: 'blur' },
                    { max: 255, message: 'Максимум 255 символов', trigger: 'blur' }
                ]
            },
            form: {
                name: '',
                color: ''
            }
        }
    },
    methods: {
        afterClose() {
            this.edit = false
            this.form = {
                name: '',
                color: ''
            }
        },
        openModal(isEdit = false, item = null) {
            if(isEdit) {
                this.edit = true
                this.form = JSON.parse(JSON.stringify(item))
            }
            this.visible = true
        },
        onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        if(this.edit) {
                            const { data } = await this.$http.put(`/calendars/${this.form.id}/`, this.form)
                            if(data) {
                                this.elementUpdate(data)
                                this.visible = false
                                this.$message.info(`Календарь ${data.name} успешно обновлен`)
                            }
                        } else {
                            const { data } = await this.$http.post('/calendars/', this.form)
                            if(data) {
                                this.listReload()
                                this.visible = false
                                this.$message.info(`Календарь ${data.name} успешно сохранен`)
                            }
                        }
                    } catch(e) {
                        console.log(e)
                        this.$message.error('Ошибка')
                    } finally {
                        this.loading = false
                    }
                } else {
                    return false
                }
            })
        }
    }
}
</script>